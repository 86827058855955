import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { subUser } from "./constants";
import { addSubUserApi, getSubUserApi, updateSubUserApi, deleteSubClientEndPointApi } from "./api";

function* getSubUserFunction() {
  try {
    yield put({
      type: subUser.GET_SUBUSER_LOADING,
      payload: {},
    });
    const response = yield call(getSubUserApi);
    if (response.data.status) {
      yield put({
        type: subUser.GET_SUBUSER_SUCCESS,
        payload: { ...response.data },
      });
    } else {
      yield put({
        type: subUser.GET_SUBUSER_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: subUser.GET_SUBUSER_ERROR,
      payload: { message: error?.message },
    });
  }
}

function* addSubUserFunction({ payload }) {
  try {
    yield put({
      type: subUser.ADD_SUBUSER_LOADING,
      payload: {},
    });
    const response = yield call(addSubUserApi, { payload });
    if (response.data.status) {
      yield put({
        type: subUser.ADD_SUBUSER_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: subUser.ADD_SUBUSER_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: subUser.ADD_SUBUSER_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {

    yield put({
      type: subUser.ADD_SUBUSER_ERROR,
      payload: error?.data,
    });
    yield put({
      type: subUser.ADD_SUBUSER_RESET,
      payload: {},
    });
  }
}

function* updateSubUserFunction({ payload }) {
  try {
    yield put({
      type: subUser.UPDATE_SUBUSER_LOADING,
      payload: {},
    });
    const response = yield call(updateSubUserApi, { payload });
    if (response.data.status) {
      yield put({
        type: subUser.UPDATE_SUBUSER_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: subUser.UPDATE_SUBUSER_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: subUser.UPDATE_SUBUSER_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: subUser.UPDATE_SUBUSER_ERROR,
      payload: error?.data,
    });
  }
}

function* deleteSubClientFunction(data) {
  try {
    yield put({
      type: subUser.DELETE_SUB_CLIENT_LOADING,
      payload: {},
    });
    const response = yield call(deleteSubClientEndPointApi, data);
    if (response.data.status) {
      yield put({
        type: subUser.DELETE_SUB_CLIENT_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: subUser.DELETE_SUB_CLIENT_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: subUser.DELETE_SUB_CLIENT_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: subUser.DELETE_SUB_CLIENT_ERROR,
      payload: error?.data,
    });
  }
}

export function* getSubUserSaga(): any {
  yield takeEvery(subUser.GET_SUBUSER, getSubUserFunction);
}

export function* addSubUserSaga(): any {
  yield takeEvery(subUser.ADD_SUBUSER, addSubUserFunction);
}

export function* updateSubUserSaga(): any {
  yield takeEvery(subUser.UPDATE_SUBUSER, updateSubUserFunction);
}

export function* deleteSubClientSaga(): any {
  yield takeEvery(subUser.DELETE_SUB_CLIENT_FIRST, deleteSubClientFunction);
}

function* subUserSaga(): any {
  yield all([
    fork(getSubUserSaga),
    fork(addSubUserSaga),
    fork(updateSubUserSaga),
    fork(deleteSubClientSaga)
  ]);
}

export default subUserSaga;
