// @flow
import { OtpSendEmailActionTypes } from './constants';

export const otpSendEmailActions = (data) => ({
    type: OtpSendEmailActionTypes.OTP_SEND_EMAIL_FIRST,
    data,
});

export const subClientSendEmailVerifyActions = (data) => ({
    type: OtpSendEmailActionTypes.SUB_CLIENT_EMAIL_SEND_VERIFY_FIRST,
    data,
});