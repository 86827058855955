import * as URL from "../../helpers/api/apiEndPoint";
import { APICore } from "../../helpers/api/apiCore";
const api = new APICore();

export function getSubUserApi(): any {
  return api.get(URL.GET_SUBUSER);
}

export function addSubUserApi(data): any {
  return api.create(URL.ADD_SUBUSER, data.payload);
}

export function updateSubUserApi(data): any {
  return api.update(URL.UPDATE_SUBUSER, data?.payload);
}

export function deleteSubClientEndPointApi(data): any {
  const { id } = data?.data
  return api.update(`${URL.DELETE_SUB_USER}userId=${id}`);
}
