export const searchAccountantType = {

    //   UPDATE
    SEARCH_ACCOUNTANT: "SEARCH_ACCOUNTANT",
    SEARCH_ACCOUNTANT_LOADING: "SEARCH_ACCOUNTANT_LOADING",
    SEARCH_ACCOUNTANT_SUCCESS: "SEARCH_ACCOUNTANT_SUCCESS",
    SEARCH_ACCOUNTANT_ERROR: "SEARCH_ACCOUNTANT_ERROR",
    SEARCH_ACCOUNTANT_RESET: "SEARCH_ACCOUNTANT_RESET",

    // Industries
    GET_FIRST_INDUSTORY: '@@auth/GET_FIRST_INDUSTORY',
    GET_LOADING_INDUSTORY: '@@auth/GET_LOADING_INDUSTORY',
    GET_SUCCESS_INDUSTORY: '@@auth/GET_SUCCESS_INDUSTORY',
    GET_ERROR_INDUSTORY: '@@auth/GET_ERROR_INDUSTORY',
    GET_RESET_INDUSTORY: '@@auth/GET_RESET_INDUSTORY',

    // Calendly token Get
    GET_FIRST_CALENDLY_API_KEY: '@@auth/GET_FIRST_CALENDLY_API_KEY',
    GET_LOADING_CALENDLY_API_KEY: '@@auth/GET_LOADING_CALENDLY_API_KEY',
    GET_SUCCESS_CALENDLY_API_KEY: '@@auth/GET_SUCCESS_CALENDLY_API_KEY',
    GET_ERROR_CALENDLY_API_KEY: '@@auth/GET_ERROR_CALENDLY_API_KEY',
    GET_RESET_CALENDLY_API_KEY: '@@auth/GET_RESET_CALENDLY_API_KEY',

    // Send Invitation
    SEND_INVITATION_TO_ACCOUNTANT: "SEND_INVITATION_TO_ACCOUNTANT",
    SEND_INVITATION_TO_ACCOUNTANT_LOADING: "SEND_INVITATION_TO_ACCOUNTANT_LOADING",
    SEND_INVITATION_TO_ACCOUNTANT_SUCCESS: "SEND_INVITATION_TO_ACCOUNTANT_SUCCESS",
    SEND_INVITATION_TO_ACCOUNTANT_ERROR: "SEND_INVITATION_TO_ACCOUNTANT_ERROR",
    SEND_INVITATION_TO_ACCOUNTANT_RESET: "SEND_INVITATION_TO_ACCOUNTANT_RESET",

    // Get Send Invitation List
    GET_SENT_INVITATION_TO_ACCOUNTANT: "GET_SENT_INVITATION_TO_ACCOUNTANT",
    GET_SENT_INVITATION_TO_ACCOUNTANT_LOADING: "GET_SENT_INVITATION_TO_ACCOUNTANT_LOADING",
    GET_SENT_INVITATION_TO_ACCOUNTANT_SUCCESS: "GET_SENT_INVITATION_TO_ACCOUNTANT_SUCCESS",
    GET_SENT_INVITATION_TO_ACCOUNTANT_ERROR: "GET_SENT_INVITATION_TO_ACCOUNTANT_ERROR",
    GET_SENT_INVITATION_TO_ACCOUNTANT_RESET: "GET_SENT_INVITATION_TO_ACCOUNTANT_RESET",

    // Post Account Validate Stripe
    POST_ACCOUNT_VALIDATE_STRIPE_: "POST_ACCOUNT_VALIDATE_STRIPE_",
    POST_ACCOUNT_VALIDATE_STRIPE_LOADING: "POST_ACCOUNT_VALIDATE_STRIPE_LOADING",
    POST_ACCOUNT_VALIDATE_STRIPE_SUCCESS: "POST_ACCOUNT_VALIDATE_STRIPE_SUCCESS",
    POST_ACCOUNT_VALIDATE_STRIPE_ERROR: "POST_ACCOUNT_VALIDATE_STRIPE_ERROR",
    POST_ACCOUNT_VALIDATE_STRIPE_RESET: "POST_ACCOUNT_VALIDATE_STRIPE_RESET",

    // Get Accountant Details
    GET_FIRST_ACCOUNTANT_DETAILS: '@@auth/GET_FIRST_ACCOUNTANT_DETAILS',
    GET_LOADING_ACCOUNTANT_DETAILS: '@@auth/GET_LOADING_ACCOUNTANT_DETAILS',
    GET_SUCCESS_ACCOUNTANT_DETAILS: '@@auth/GET_SUCCESS_ACCOUNTANT_DETAILS',
    GET_ERROR_ACCOUNTANT_DETAILS: '@@auth/GET_ERROR_ACCOUNTANT_DETAILS',
    GET_RESET_ACCOUNTANT_DETAILS: '@@auth/GET_RESET_ACCOUNTANT_DETAILS',

    // Get Client Accountant Details
    GET_FIRST_CLIENT_ACCOUNTANT_DETAILS: '@@auth/GET_FIRST_CLIENT_ACCOUNTANT_DETAILS',
    GET_LOADING_CLIENT_ACCOUNTANT_DETAILS: '@@auth/GET_LOADING_CLIENT_ACCOUNTANT_DETAILS',
    GET_SUCCESS_CLIENT_ACCOUNTANT_DETAILS: '@@auth/GET_SUCCESS_CLIENT_ACCOUNTANT_DETAILS',
    GET_ERROR_CLIENT_ACCOUNTANT_DETAILS: '@@auth/GET_ERROR_CLIENT_ACCOUNTANT_DETAILS',
    GET_RESET_CLIENT_ACCOUNTANT_DETAILS: '@@auth/GET_RESET_CLIENT_ACCOUNTANT_DETAILS',

    // update Payment Method
    UPDATE_PAYMENT_METHOD_FIRST__DETAILS: '@@auth/UPDATE_PAYMENT_METHOD_FIRST__DETAILS',
    UPDATE_PAYMENT_METHOD_LOADING__DETAILS: '@@auth/UPDATE_PAYMENT_METHOD_LOADING__DETAILS',
    UPDATE_PAYMENT_METHOD_SUCCESS__DETAILS: '@@auth/UPDATE_PAYMENT_METHOD_SUCCESS__DETAILS',
    UPDATE_PAYMENT_METHOD_ERROR__DETAILS: '@@auth/UPDATE_PAYMENT_METHOD_ERROR__DETAILS',
    UPDATE_PAYMENT_METHOD_RESET__DETAILS: '@@auth/UPDATE_PAYMENT_METHOD_RESET__DETAILS',

    // Generate Contract
    GENERATE_CONTRACT_FIRST__DETAILS: '@@auth/GENERATE_CONTRACT_FIRST__DETAILS',
    GENERATE_CONTRACT_LOADING__DETAILS: '@@auth/GENERATE_CONTRACT_LOADING__DETAILS',
    GENERATE_CONTRACT_SUCCESS__DETAILS: '@@auth/GENERATE_CONTRACT_SUCCESS__DETAILS',
    GENERATE_CONTRACT_ERROR__DETAILS: '@@auth/GENERATE_CONTRACT_ERROR__DETAILS',
    GENERATE_CONTRACT_RESET__DETAILS: '@@auth/GENERATE_CONTRACT_RESET__DETAILS',

    // Docusign token Get
    GET_FIRST_DOCUSIGN_API_KEY: '@@auth/GET_FIRST_DOCUSIGN_API_KEY',
    GET_LOADING_DOCUSIGN_API_KEY: '@@auth/GET_LOADING_DOCUSIGN_API_KEY',
    GET_SUCCESS_DOCUSIGN_API_KEY: '@@auth/GET_SUCCESS_DOCUSIGN_API_KEY',
    GET_ERROR_DOCUSIGN_API_KEY: '@@auth/GET_ERROR_DOCUSIGN_API_KEY',
    GET_RESET_DOCUSIGN_API_KEY: '@@auth/GET_RESET_DOCUSIGN_API_KEY',

    // Get Contract List
    GET_FIRST_CONTRACT_LIST: '@@auth/GET_FIRST_CONTRACT_LIST',
    GET_LOADING_CONTRACT_LIST: '@@auth/GET_LOADING_CONTRACT_LIST',
    GET_SUCCESS_CONTRACT_LIST: '@@auth/GET_SUCCESS_CONTRACT_LIST',
    GET_ERROR_CONTRACT_LIST: '@@auth/GET_ERROR_CONTRACT_LIST',
    GET_RESET_CONTRACT_LIST: '@@auth/GET_RESET_CONTRACT_LIST',

    // Get Client Contract List
    GET_FIRST_CLIENT_CONTRACT_LIST: '@@auth/GET_FIRST_CLIENT_CONTRACT_LIST',
    GET_LOADING_CLIENT_CONTRACT_LIST: '@@auth/GET_LOADING_CLIENT_CONTRACT_LIST',
    GET_SUCCESS_CLIENT_CONTRACT_LIST: '@@auth/GET_SUCCESS_CLIENT_CONTRACT_LIST',
    GET_ERROR_CLIENT_CONTRACT_LIST: '@@auth/GET_ERROR_CLIENT_CONTRACT_LIST',
    GET_RESET_CLIENT_CONTRACT_LIST: '@@auth/GET_RESET_CONTRACT_LIST',
};
