import { VerifyDetailActionTypes } from './constants';

const GET_COMPANY_NAME_INITIAL_STATE = {
    getCompanyName: [],
    loading: false,
};

const REGISTER_USER_INITIAL_STATE = {
    registerUser: [],
    loading: false,
};

const COMPANY_CHECK_VERIFY_INITIAL_STATE = {
    companyCheckVerify: [],
    loading: false,
};

const COMPANY_CREATE_INITIAL_STATE = {
    companyCreate: [],
    loading: false,
};


const getCompanyNameReducer = (
    state = GET_COMPANY_NAME_INITIAL_STATE,
    action
) => {
    // console.log(action.payload,'reducer')
    switch (action.type) {
        case VerifyDetailActionTypes.GET_COMPANY_NAME_LOADING:
            return {
                getCompanyName: state.getCompanyName,
                loading: true,
            };
        case VerifyDetailActionTypes.GET_COMPANY_NAME_SUCCESS:
            return {
                getCompanyName: action.payload,
                loading: false,
            };
        case VerifyDetailActionTypes.GET_COMPANY_NAME_ERROR:
            return {
                getCompanyName: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};

const registerUserReducer = (
    state = REGISTER_USER_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case VerifyDetailActionTypes.REGISTER_USER_LOADING:
            return {
                registerUser: state.registerUser,
                loading: true,
            };
        case VerifyDetailActionTypes.REGISTER_USER_SUCCESS:
            return {
                registerUser: action.payload,
                loading: false,
            };
        case VerifyDetailActionTypes.REGISTER_USER_ERROR:
            return {
                registerUser: action.payload,
                loading: false,
            };
        case VerifyDetailActionTypes.REGISTER_USER_RESET:
            return REGISTER_USER_INITIAL_STATE
        default:
            return state;
    }
};

const companyCheckVerifyReducer = (
    state = COMPANY_CHECK_VERIFY_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case VerifyDetailActionTypes.COMPANY_CHECK_VERIFY_LOADING:
            return {
                companyCheckVerify: state.companyCheckVerify,
                loading: true,
            };
        case VerifyDetailActionTypes.COMPANY_CHECK_VERIFY_SUCCESS:
            return {
                companyCheckVerify: action.payload,
                loading: false,
            };
        case VerifyDetailActionTypes.COMPANY_CHECK_VERIFY_ERROR:
            return {
                companyCheckVerify: action.payload,
                loading: false,
            };
        case VerifyDetailActionTypes.COMPANY_CHECK_VERIFY_RESET:
            return COMPANY_CHECK_VERIFY_INITIAL_STATE
        default:
            return state;
    }
};

const companyCreateReducer = (
    state = COMPANY_CREATE_INITIAL_STATE,
    action
) => {
    // console.log(action, 'action')

    switch (action.type) {
        case VerifyDetailActionTypes.COMPANY_CREATE_LOADING:
            return {
                companyCreate: state.companyCreate,
                loading: true,
            };
        case VerifyDetailActionTypes.COMPANY_CREATE_SUCCESS:
            return {
                companyCreate: action.payload,
                loading: false,
            };
        case VerifyDetailActionTypes.COMPANY_CREATE_ERROR:
            return {
                companyCreate: action.payload,
                loading: false,
            };
        case VerifyDetailActionTypes.COMPANY_CREATE_RESET:
            return COMPANY_CREATE_INITIAL_STATE
        default:
            return state;
    }
};

export {
    getCompanyNameReducer,
    registerUserReducer,
    companyCheckVerifyReducer,
    companyCreateReducer
}
