import { announcements } from "./constant";

const initial_state = {
  data: [],
  message: "",
  loading: false,
};

export const getAnnouncementsReducer = (state = initial_state, action) => {
  switch (action.type) {
    case announcements.GET_ANNOUNCEMENT_LOADING:
      return {
        data: initial_state.data,
        loading: true,
      };
    case announcements.GET_ANNOUNCEMENT_SUCCESS:
      return {
        data: action?.payload,
        loading: false,
      };
    case announcements.GET_ANNOUNCEMENT_ERROR:
      return {
        data: [],
        loading: false,
        message: action?.payload,
      };
    default:
      return { ...state };
  }
};
