export const subUser = {
  // Get
  GET_SUBUSER: "GET_SUBUSER",
  GET_SUBUSER_LOADING: "GET_SUBUSER_LOADING",
  GET_SUBUSER_SUCCESS: "GET_SUBUSER_SUCCESS",
  GET_SUBUSER_ERROR: "GET_SUBUSER_ERROR",
  GET_SUBUSER_RESET: "GET_SUBUSER_RESET",

  // Post
  ADD_SUBUSER: "ADD_SUBUSER",
  ADD_SUBUSER_LOADING: "ADD_SUBUSER_LOADING",
  ADD_SUBUSER_SUCCESS: "ADD_SUBUSER_SUCCESS",
  ADD_SUBUSER_ERROR: "ADD_SUBUSER_ERROR",
  ADD_SUBUSER_RESET: "ADD_SUBUSER_RESET",

  // Update
  UPDATE_SUBUSER: "UPDATE_SUBUSER",
  UPDATE_SUBUSER_LOADING: "UPDATE_SUBUSER_LOADING",
  UPDATE_SUBUSER_SUCCESS: "UPDATE_SUBUSER_SUCCESS",
  UPDATE_SUBUSER_ERROR: "UPDATE_SUBUSER_ERROR",
  UPDATE_SUBUSER_RESET: "UPDATE_SUBUSER_RESET",

  DELETE_SUB_CLIENT_FIRST: "DELETE_SUB_CLIENT_FIRST",
  DELETE_SUB_CLIENT_LOADING: "DELETE_SUB_CLIENT_LOADING",
  DELETE_SUB_CLIENT_SUCCESS: "DELETE_SUB_CLIENT_SUCCESS",
  DELETE_SUB_CLIENT_ERROR: "DELETE_SUB_CLIENT_ERROR",
  DELETE_SUB_CLIENT_RESET: "DELETE_SUB_CLIENT_RESET",
};
