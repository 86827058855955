import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { OrganisationActionTypes } from "./constants";
import {
  organisationSettingEndPoint,
  getOrganisationSettingEndPoint,
  emailVerifySendEndPoint,
  DeleteEmailVerifySendEndPoint,
  otpVerifySendEndPoint,
} from "./api";

function* organisationSettingFunction(data) {
  try {
    yield put({
      type: OrganisationActionTypes.ORGANISATION_SETTING_LOADING,
      payload: {},
    });
    const response = yield call(organisationSettingEndPoint, data);
    if (response.data.status) {
      yield put({
        type: OrganisationActionTypes.ORGANISATION_SETTING_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: OrganisationActionTypes.ORGANISATION_SETTING_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: OrganisationActionTypes.ORGANISATION_SETTING_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: OrganisationActionTypes.ORGANISATION_SETTING_ERROR,
      payload: error?.data,
    });
    yield put({
      type: OrganisationActionTypes.ORGANISATION_SETTING_RESET,
      payload: {},
    });
  }
}

function* getOrganisationSettingFunction(data) {
  try {
    yield put({
      type: OrganisationActionTypes.GET_ORGANISATION_SETTING_LOADING,
      payload: {},
    });
    const response = yield call(getOrganisationSettingEndPoint, data);
    // console.log(data, "00000000987654");
    if (response.data.status) {
      yield put({
        type: OrganisationActionTypes.GET_ORGANISATION_SETTING_SUCCESS,
        payload: { ...response.data },
      });
    } else {
      yield put({
        type: OrganisationActionTypes.GET_ORGANISATION_SETTING_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: OrganisationActionTypes.GET_ORGANISATION_SETTING_ERROR,
      payload: error?.data,
    });
    // yield put({
    //     type: OrganisationActionTypes.GET_ORGANISATION_SETTING_RESET,
    //     payload: {},
    // });
  }
}

function* emailVerifySendFunction(data) {
  try {
    yield put({
      type: OrganisationActionTypes.EMAIL_VERIFY_SEND_LOADING,
      payload: {},
    });
    const response = yield call(emailVerifySendEndPoint, data);
    if (response.data.status) {
      yield put({
        type: OrganisationActionTypes.EMAIL_VERIFY_SEND_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: OrganisationActionTypes.EMAIL_VERIFY_SEND_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: OrganisationActionTypes.EMAIL_VERIFY_SEND_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: OrganisationActionTypes.EMAIL_VERIFY_SEND_ERROR,
      payload: error?.data,
    });
    yield put({
      type: OrganisationActionTypes.EMAIL_VERIFY_SEND_RESET,
      payload: {},
    });
  }
}

function* deleteEmailVerifySendFunction(data) {
  try {
    yield put({
      type: OrganisationActionTypes.DELETE_EMAIL_VERIFY_SEND_LOADING,
      payload: {},
    });
    const response = yield call(DeleteEmailVerifySendEndPoint, data);
    if (response.data.status) {
      yield put({
        type: OrganisationActionTypes.DELETE_EMAIL_VERIFY_SEND_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: OrganisationActionTypes.DELETE_EMAIL_VERIFY_SEND_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: OrganisationActionTypes.DELETE_EMAIL_VERIFY_SEND_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: OrganisationActionTypes.DELETE_EMAIL_VERIFY_SEND_ERROR,
      payload: error?.data,
    });
    yield put({
      type: OrganisationActionTypes.DELETE_EMAIL_VERIFY_SEND_RESET,
      payload: {},
    });
  }
}

function* otpVerifySendFunction(data) {
  try {
    yield put({
      type: OrganisationActionTypes.OTP_VERIFY_SEND_LOADING,
      payload: {},
    });
    const response = yield call(otpVerifySendEndPoint, data);
    if (response.data.status) {
      yield put({
        type: OrganisationActionTypes.OTP_VERIFY_SEND_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: OrganisationActionTypes.OTP_VERIFY_SEND_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: OrganisationActionTypes.OTP_VERIFY_SEND_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: OrganisationActionTypes.OTP_VERIFY_SEND_ERROR,
      payload: error?.data,
    });
    yield put({
      type: OrganisationActionTypes.OTP_VERIFY_SEND_RESET,
      payload: {},
    });
  }
}

export function* acctionOrganisationSetting(): any {
  yield takeEvery(
    OrganisationActionTypes.ORGANISATION_SETTING_FIRST,
    organisationSettingFunction
  );
}

export function* acctionGetOrganisationSetting(): any {
  yield takeEvery(
    OrganisationActionTypes.GET_ORGANISATION_SETTING_FIRST,
    getOrganisationSettingFunction
  );
}

export function* acctionEmailVerifySend(): any {
  yield takeEvery(
    OrganisationActionTypes.EMAIL_VERIFY_SEND_FIRST,
    emailVerifySendFunction
  );
}
export function* acctionDeleteEmailVerifySend(): any {
  yield takeEvery(
    OrganisationActionTypes.DELETE_EMAIL_VERIFY_SEND_FIRST,
    deleteEmailVerifySendFunction
  );
}

export function* acctionOtpVerifySend(): any {
  yield takeEvery(
    OrganisationActionTypes.OTP_VERIFY_SEND_FIRST,
    otpVerifySendFunction
  );
}
function* organisationSaga(): any {
  yield all([
    fork(acctionOrganisationSetting),
    fork(acctionGetOrganisationSetting),
    fork(acctionEmailVerifySend),
    fork(acctionDeleteEmailVerifySend),
    fork(acctionOtpVerifySend),
  ]);
}

export default organisationSaga;
