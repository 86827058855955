import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import AccountType from '../../accountPro/profile/constant';
import {
    uploadProfileApi,
    getProfileApi,
    uploadPasswordApi,
    accountDetailEndPoint,
    mobileNumberVerifyUpdateEndPoint,
    mobileNumberVerifyOtpSendEndPoint
} from './api';


// function* updateProfileFunction({ payload }) {
//     try {

//         const response = yield call(uploadProfileApi, { payload });
//         if (response.data.status === 200) {
//             yield put({
//                 type: AccountType.UPDATE_PROFILE_SUCCESS,
//                 payload: { ...response.data },
//             });
//             yield put({
//                 type: AccountType.UPDATE_PROFILE_RESET,
//                 payload: {},
//             });
//         }
//         else {
//             yield put({
//                 type: AccountType.UPDATE_PROFILE_ERROR,
//                 payload: { ...response.data }
//             });

//         }

//     } catch (error) {

//         yield put({
//             type: AccountType.UPDATE_PROFILE_ERROR,
//             payload: { message: error }
//         });

//     }
// }

function* updateProfileFunction(data) {
    try {
        yield put({
            type: AccountType.UPDATE_PROFILE_LOADING,
            payload: {},
        });
        const response = yield call(uploadProfileApi, data);
        if (response.data.status) {
            yield put({
                type: AccountType.UPDATE_PROFILE_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: AccountType.UPDATE_PROFILE_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: AccountType.UPDATE_PROFILE_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: AccountType.UPDATE_PROFILE_ERROR,
            payload: error?.data,
        });
        yield put({
            type: AccountType.UPDATE_PROFILE_RESET,
            payload: {},
        });
    }
}


function* updatePasswordFunction({ payload }) {
    try {

        const response = yield call(uploadPasswordApi, { payload });
        if (response.data != null) {
            yield put({
                type: AccountType.UPDATE_PASSWORD_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: AccountType.UPDATE_PASSWORD_RESET,
                payload: {},
            });
        }
        else {
            yield put({
                type: AccountType.UPDATE_PASSWORD_ERROR,
                payload: { ...response.data }
            });

        }

    } catch (error) {

        yield put({
            type: AccountType.UPDATE_PASSWORD_ERROR,
            payload: error?.data
        });

    }
}

function* getProfileFunction({ payload }) {

    try {
        yield put({
            type: AccountType.GET_PROFILE_LOADING,
            payload: {},
        });
        const response = yield call(getProfileApi, { payload });
        if (response.data.status === 200) {
            yield put({
                type: AccountType.GET_PROFILE_SUCCESS,
                payload: { ...response.data },
            });

        }
        else {
            yield put({
                type: AccountType.GET_PROFILE_ERROR,
                payload: { ...response.data }
            });
        }
    } catch (error) {
        yield put({
            type: AccountType.GET_PROFILE_ERROR,
            payload: { message: error?.message }
        });
        yield put({
            type: AccountType.GET_PROFILE_RESET,
            payload: {},
        });
    }

    try {

        const response = yield call(getProfileApi, { payload });
        if (response.data.status === 200) {
            yield put({
                type: AccountType.GET_PROFILE_SUCCESS,
                payload: { ...response.data },
            });

        }
        else {
            yield put({
                type: AccountType.GET_PROFILE_ERROR,
                payload: { ...response.data }
            });
        }

    } catch (error) {
        yield put({
            type: AccountType.GET_PROFILE_ERROR,
            payload: { message: error?.message }
        });

    }
}

function* accountDetailFunction(data) {
    try {
        yield put({
            type: AccountType.ACCOUNT_DETAIL_ADD_LOADING,
            payload: {},
        });
        const response = yield call(accountDetailEndPoint, data);
        if (response.data.status) {
            yield put({
                type: AccountType.ACCOUNT_DETAIL_ADD_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: AccountType.ACCOUNT_DETAIL_ADD_RESET,
                payload: {},
            });

        } else {
            yield put({
                type: AccountType.ACCOUNT_DETAIL_ADD_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: AccountType.ACCOUNT_DETAIL_ADD_ERROR,
            payload: error?.data,
        });
        yield put({
            type: AccountType.ACCOUNT_DETAIL_ADD_RESET,
            payload: {},
        });
    }
}
// NEW CODE
function* mobileNumberVerifyFunction(data) {
    try {
        yield put({
            type: AccountType.MOBILE_NUMBER_VERIFY_LOADING,
            payload: {},
        });
        const response = yield call(mobileNumberVerifyUpdateEndPoint, data);
        if (response.data.status) {
            yield put({
                type: AccountType.MOBILE_NUMBER_VERIFY_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: AccountType.MOBILE_NUMBER_VERIFY_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: AccountType.MOBILE_NUMBER_VERIFY_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: AccountType.MOBILE_NUMBER_VERIFY_ERROR,
            payload: error?.data,
        });
        yield put({
            type: AccountType.MOBILE_NUMBER_VERIFY_RESET,
            payload: {},
        });
    }
}

function* mobileNumberVerifyOTPsendFunction(data) {
    try {
        yield put({
            type: AccountType.MOBILE_NUMBER_VERIFY_OTP_SEND_LOADING,
            payload: {},
        });
        const response = yield call(mobileNumberVerifyOtpSendEndPoint, data);
        if (response.data.status) {
            yield put({
                type: AccountType.MOBILE_NUMBER_VERIFY_OTP_SEND_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: AccountType.MOBILE_NUMBER_VERIFY_OTP_SEND_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: AccountType.MOBILE_NUMBER_VERIFY_OTP_SEND_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: AccountType.MOBILE_NUMBER_VERIFY_OTP_SEND_ERROR,
            payload: error?.data,
        });
        yield put({
            type: AccountType.MOBILE_NUMBER_VERIFY_OTP_SEND_RESET,
            payload: {},
        });
    }
}

export function* updateProfileSaga(): any {
    yield takeEvery(AccountType.UPDATE_PROFILE, updateProfileFunction);
}

export function* getProfileSaga(): any {
    yield takeEvery(AccountType.GET_PROFILE, getProfileFunction);
}
export function* getPasswordSaga(): any {
    yield takeEvery(AccountType.UPDATE_PASSWORD, updatePasswordFunction);
}

export function* acctionAccountDetail(): any {
    yield takeEvery(AccountType.ACCOUNT_DETAIL_ADD_FIRST, accountDetailFunction);
}

export function* acctioMobileNumberVerify(): any {
    yield takeEvery(AccountType.MOBILE_NUMBER_VERIFY_FIRST, mobileNumberVerifyFunction);
}

export function* acctioMobileNumberVerifyOTPsend(): any {
    yield takeEvery(AccountType.MOBILE_NUMBER_VERIFY_OTP_SEND_FIRST, mobileNumberVerifyOTPsendFunction);
}



function* ProfileSaga(): any {
    yield all([
        fork(updateProfileSaga),
        fork(getProfileSaga),
        fork(getPasswordSaga),
        fork(acctionAccountDetail),
        fork(acctioMobileNumberVerify),
        fork(acctioMobileNumberVerifyOTPsend)
    ])
}
export default ProfileSaga;