import { OtpSendEmailActionTypes } from './constants';

const VERIFY_OTP_EMAIL_INITIAL_STATE = {
    verifyOtpEmail: [],
    loading: false,
};
const SUB_CLIENT_VERIFY__EMAIL_INITIAL_STATE = {
    subClientVerifyEmailSend: [],
    loading: false,
};

const otpSendEmailReducer = (
    state = VERIFY_OTP_EMAIL_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case OtpSendEmailActionTypes.OTP_SEND_EMAIL_LOADING:
            return {
                verifyOtpEmail: state.verifyOtpEmail,
                loading: true,
            };
        case OtpSendEmailActionTypes.OTP_SEND_EMAIL_SUCCESS:
            return {
                verifyOtpEmail: action.payload,
                loading: false,
            };
        case OtpSendEmailActionTypes.OTP_SEND_EMAIL_ERROR:
            return {
                verifyOtpEmail: action.payload,
                loading: false,
            };
        case OtpSendEmailActionTypes.OTP_SEND_EMAIL_RESET:
            return VERIFY_OTP_EMAIL_INITIAL_STATE;
        default:
            return state;
    }
};

const subUserSendEmailVerifyReducer = (
    state = SUB_CLIENT_VERIFY__EMAIL_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case OtpSendEmailActionTypes.SUB_CLIENT_EMAIL_SEND_VERIFY_LOADING:
            return {
                subClientVerifyEmailSend: state.subClientVerifyEmailSend,
                loading: true,
            };
        case OtpSendEmailActionTypes.SUB_CLIENT_EMAIL_SEND_VERIFY_SUCCESS:
            return {
                subClientVerifyEmailSend: action.payload,
                loading: false,
            };
        case OtpSendEmailActionTypes.SUB_CLIENT_EMAIL_SEND_VERIFY_ERROR:
            return {
                subClientVerifyEmailSend: action.payload,
                loading: false,
            };
        case OtpSendEmailActionTypes.SUB_CLIENT_EMAIL_SEND_VERIFY_RESET:
            return SUB_CLIENT_VERIFY__EMAIL_INITIAL_STATE;
        default:
            return state;
    }
};


export {
    otpSendEmailReducer,
    subUserSendEmailVerifyReducer
}
