// import React from 'react';
// import ReactDOM from 'react-dom';

// import { I18nextProvider } from 'react-i18next';
// import App from './App';

// import { Provider } from 'react-redux';
// import { configureStore } from './redux/store';
// import { ReactNotifications } from 'react-notifications-component';
// import 'react-notifications-component/dist/theme.css'
// import i18n from './i18n';

// ReactDOM.render(
//    <I18nextProvider i18n={i18n}>
//     <Provider store={configureStore({})}>
//         <ReactNotifications />
//         <App />
//     </Provider>
//     </I18nextProvider>
//     ,
//     document.getElementById('root')
// );

import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { ReactNotifications } from "react-notifications-component";

import App from "./App";
import { configureStore } from "./redux/store";
import i18n from "./i18n";
import "react-notifications-component/dist/theme.css";
const stripePromise = loadStripe(
  "pk_test_51PkLSLCpUfrUUXPdjsWG0ocCsYHbX335QPLRUycTreU2rQ0Hj0Y6CC3AYzWqlS6u8LotSxgTwZsrZ1rnQs396hPw00KNAPnQP3"
);
const clientSecret =
  "sk_test_51PkLSLCpUfrUUXPdjo1iwggq84WcJpiUSpENcIfQZpKYRIp9ulrB24Ji9XMIeF5Bg2FCOGFn4f7XzN2cU9x2k0V300dv97OtKv"; // Replace with your actual client secret

const options = {
  clientSecret,
  appearance: {
    theme: "stripe",
  },
};
ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={configureStore({})}>
      <ReactNotifications />
      <App />
    </Provider>
  </I18nextProvider>,
  document.getElementById("root")
);
