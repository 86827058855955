import { APICore } from "../../../helpers/api/apiCore";
import * as URL from "../../../helpers/api/apiEndPoint";
const api = new APICore();

function getVatDetails(params: any): any {
    const { year } = params?.data;
    return api.get(
        `${URL.GET_VAT_DETAILS_FOR_CLEINT}year=${year}`
    );
}


export { getVatDetails };
