// @flow
import { ResetPasswordActionTypes } from './constants';

export const resetPasswordEmailActions = (data) => ({
    type: ResetPasswordActionTypes.RESET_PASSWORD_EMAIL_FIRST,
    data,
});

export const resetPasswordVerifyActions = (data) => ({
    type: ResetPasswordActionTypes.RESET_PASSWORD_VERIFY_FIRST,
    data,
});

