import { APICore } from '../../../helpers/api/apiCore';
import * as URL from '../../../helpers/api/apiEndPoint';

const api = new APICore();

function businessEmailEndPoint(params: any): any {
    const { data } = params;
    return api.create(URL.BUSINESS_EMAIL_OTP, data);
}


export {
    businessEmailEndPoint,
};
