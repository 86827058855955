import AccountType from "../../accountPro/profile/constant";

const GET_PROFILE_INITIAL_STATE = {
    data: [],
    message: "",
    loading: false,
};
const UPDATE_PROFILE_INITIAL_STATE = {
    updateClientProfile: [],
    loading: false
}
const UPDATE_PASSWORD_INITIAL_STATE = {
    data: [],
    loading: false
}
const ACCOUNT_DETAIL_INITIAL_STATE = {
    accountDetail: [],
    loading: false,
};

const MOBILE_NUMBER_VERIFY_INITIAL_STATE = {
    mobileVerify: [],
    loading: false,
};
const MOBILE_NUMBER_VERIFY_OTP_SEND_INITIAL_STATE = {
    mobileOtpSend: [],
    loading: false,
};
export const getProfileReducer = (state = GET_PROFILE_INITIAL_STATE, action) => {
    switch (action.type) {
        case AccountType.GET_PROFILE_LOADING:
            return {
                data: GET_PROFILE_INITIAL_STATE.data,
                loading: true,
            };
        case AccountType.GET_PROFILE_SUCCESS:
            return {
                data: action?.payload,
                loading: false,
            };
        case AccountType.GET_PROFILE_ERROR:
            return {
                data: [],
                loading: false,
                message: action?.payload,
            };
        default:
            return { ...state };

    }
};

export const updateProfileReducer = (
    state = UPDATE_PROFILE_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case AccountType.UPDATE_PROFILE_LOADING:
            return {
                updateClientProfile: state.updateClientProfile,
                loading: true,
            };
        case AccountType.UPDATE_PROFILE_SUCCESS:
            return {
                updateClientProfile: action?.payload,
                loading: false,
            };
        case AccountType.UPDATE_PROFILE_ERROR:
            return {
                updateClientProfile: action?.payload,
                loading: false,
            }
        case AccountType.UPDATE_PROFILE_RESET:
            return UPDATE_PROFILE_INITIAL_STATE
        default:
            return { ...state };

    }
};

export const updatePasswordReducer = (state = UPDATE_PASSWORD_INITIAL_STATE, action) => {
    switch (action.type) {
        case AccountType.UPDATE_PASSWORD_LOADING:
            return {
                data: UPDATE_PASSWORD_INITIAL_STATE.data,
                loading: true,
            };
        case AccountType.UPDATE_PASSWORD_SUCCESS:
            return {
                data: action?.payload,
                loading: false,
            };
        case AccountType.UPDATE_PASSWORD_RESET:
            return {
                data: UPDATE_PASSWORD_INITIAL_STATE.data,
                loading: false
            }

        case AccountType.UPDATE_PASSWORD_ERROR:
            return {
                loading: false,
                data: action?.payload,
            };
        default:
            return { ...state };

    }
};

export const accountDetailReducer = (
    state = ACCOUNT_DETAIL_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case AccountType.ACCOUNT_DETAIL_ADD_LOADING:
            return {
                accountDetail: state.accountDetail,
                loading: true,
            };
        case AccountType.ACCOUNT_DETAIL_ADD_SUCCESS:
            return {
                accountDetail: action.payload,
                loading: false,
            };
        case AccountType.ACCOUNT_DETAIL_ADD_ERROR:
            return {
                accountDetail: action.payload,
                loading: false,
            };
        case AccountType.ACCOUNT_DETAIL_ADD_RESET:
            return ACCOUNT_DETAIL_INITIAL_STATE;
        default:
            return state;
    }
};

export const mobileNumberVerifyReducer = (
    state = MOBILE_NUMBER_VERIFY_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case AccountType.MOBILE_NUMBER_VERIFY_LOADING:
            return {
                mobileVerify: state.mobileVerify,
                loading: true,
            };
        case AccountType.MOBILE_NUMBER_VERIFY_SUCCESS:
            return {
                mobileVerify: action.payload,
                loading: false,
            };
        case AccountType.MOBILE_NUMBER_VERIFY_ERROR:
            return {
                mobileVerify: action.payload,
                loading: false,
            };
        case AccountType.MOBILE_NUMBER_VERIFY_RESET:
            return MOBILE_NUMBER_VERIFY_INITIAL_STATE;
        default:
            return state;
    }
};

export const mobileNumberVerifyOtpSendReducer = (
    state = MOBILE_NUMBER_VERIFY_OTP_SEND_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case AccountType.MOBILE_NUMBER_VERIFY_OTP_SEND_LOADING:
            return {
                mobileOtpSend: state.mobileOtpSend,
                loading: true,
            };
        case AccountType.MOBILE_NUMBER_VERIFY_OTP_SEND_SUCCESS:
            return {
                mobileOtpSend: action.payload,
                loading: false,
            };
        case AccountType.MOBILE_NUMBER_VERIFY_OTP_SEND_ERROR:
            return {
                mobileOtpSend: action.payload,
                loading: false,
            };
        case AccountType.MOBILE_NUMBER_VERIFY_OTP_SEND_RESET:
            return MOBILE_NUMBER_VERIFY_OTP_SEND_INITIAL_STATE;
        default:
            return state;
    }
};