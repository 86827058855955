import { subUser } from "./constants";

const initial_state = {
  data: [],
  message: "",
  loading: false,
};
const ADD_SUBUSER_INITIAL_STATE = {
  subUserAdd: [],
  loading: false
}

const UPDATE_SUBUSER_INITIAL_STATE = {
  subUserUpdate: [],
  loading: false
}
const DELETE_SUB_CLIENT_INITIAL_STATE = {
  deleteSubClient: [],
  loading: false
}
export const getSubUserReducer = (state = initial_state, action) => {
  switch (action.type) {
    case subUser.GET_SUBUSER_LOADING:
      return {
        data: initial_state.data,
        loading: true,
      };
    case subUser.GET_SUBUSER_SUCCESS:
      return {
        data: action?.payload,
        loading: false,
      };
    case subUser.GET_SUBUSER_ERROR:
      return {
        data: [],
        loading: false,
        message: action?.payload,
      };
    default:
      return { ...state };
  }
};

export const addSubUserReducer = (state = ADD_SUBUSER_INITIAL_STATE, action) => {
  switch (action.type) {
    case subUser.ADD_SUBUSER_LOADING:
      return {
        subUserAdd: state?.subUserAdd,
        loading: true,
      };
    case subUser.ADD_SUBUSER_SUCCESS:
      return {
        subUserAdd: action?.payload,
        loading: false,
      };
    case subUser.ADD_SUBUSER_RESET:
      return {
        subUserAdd: initial_state.data,
        loading: false,
      };
    case subUser.ADD_SUBUSER_ERROR:
      return {
        subUserAdd: action?.payload,
        loading: false,
        // message: action?.payload,
      };
    default:
      return { ...state };
  }
};

export const updateSubUserReducer = (state = UPDATE_SUBUSER_INITIAL_STATE, action) => {
  switch (action.type) {
    case subUser.UPDATE_SUBUSER_LOADING:
      return {
        subUserUpdate: state.subUserUpdate,
        loading: true,
      };
    case subUser.UPDATE_SUBUSER_SUCCESS:
      return {
        subUserUpdate: action?.payload,
        loading: false,
      };
    case subUser.UPDATE_SUBUSER_RESET:
      return {
        subUserUpdate: state,
        loading: false,
      };
    case subUser.UPDATE_SUBUSER_ERROR:
      return {
        subUserUpdate: action?.payload,
        loading: false,
        // message: action?.payload,
      };
    default:
      return { ...state };
  }
};

export const deleteSubClientReducer = (state = DELETE_SUB_CLIENT_INITIAL_STATE, action) => {
  switch (action.type) {
    case subUser.DELETE_SUB_CLIENT_LOADING:
      return {
        deleteSubClient: state.deleteSubClient,
        loading: true,
      };
    case subUser.DELETE_SUB_CLIENT_SUCCESS:
      return {
        deleteSubClient: action?.payload,
        loading: false,
      };
    case subUser.DELETE_SUB_CLIENT_RESET:
      return {
        deleteSubClient: state,
        loading: false,
      };
    case subUser.DELETE_SUB_CLIENT_ERROR:
      return {
        deleteSubClient: action?.payload,
        loading: false,
        // message: action?.payload,
      };
    default:
      return { ...state };
  }
};
