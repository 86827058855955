import { VatDetailsActionTypes } from "./constant";

const GET_VAT_DETAILS_INITIAL_STATE = {
    getVatDetails: [],
    loading: false,
};

const getVatDetailsReducer = (
    state = GET_VAT_DETAILS_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case VatDetailsActionTypes.GET_VAT_DETAILS_LOADING:
            return {
                getVatDetails: state.getVatDetails,
                loading: true,
            };
        case VatDetailsActionTypes.GET_VAT_DETAILS_SUCCESS:
            return {
                getVatDetails: action.payload,
                loading: false,
            };
        case VatDetailsActionTypes.GET_VAT_DETAILS_ERROR:
            return {
                getVatDetails: action.payload,
                loading: false,
            };

        default:
            return state;
    }
};

export {
    getVatDetailsReducer,
};
