// @flow
import { AuthComponentDataSend } from "./constants";
import { StateEmptyActionTypes } from "../../stateEmpty/constants";
export const otpTimeStartActions = () => ({
  type: AuthComponentDataSend.OTP_TIME_START_FIRST,
});

export const signupVerifyBackBtnActions = () => ({
  type: AuthComponentDataSend.SIGNUP_VERIFY_BACK_BTN_FIRST,
});

export const stateEmptyActions = () => ({
  type: StateEmptyActionTypes.STATE_EMPTY_FIRST,
});

export const mbScrnSideBrClseActions = () => ({
  type: AuthComponentDataSend.MOBILE_SCREEN_SIDE_BAR_CLOSE_FIRST,
});
