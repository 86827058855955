import AccountType from ".././../../redux/accountPro/profile/constant";

export const updateProfile = (data): AuthAction => ({
  type: AccountType.UPDATE_PROFILE,
  payload: data,
});
export const getProfile = (data): AuthAction => ({
  type: AccountType.GET_PROFILE,
  payload: data,
});
export const updatePassword = (data): AuthAction => ({
  type: AccountType.UPDATE_PASSWORD,
  payload: data,
});
export const AccountDetailAction = (data): AuthAction => ({
  type: AccountType.ACCOUNT_DETAIL_ADD_FIRST,
  data,
});

export const mobileNumberVerifyAction = (data): AuthAction => ({
  type: AccountType.MOBILE_NUMBER_VERIFY_FIRST,
  data,
});

export const mobileNumberVerifyOTPSendAction = (data): AuthAction => ({
  type: AccountType.MOBILE_NUMBER_VERIFY_OTP_SEND_FIRST,
  data,
});
