import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { VatDetailsActionTypes } from "./constant";
import { getVatDetails } from "./api";

function* callGetVatDetails(data) {
    try {
        yield put({
            type: VatDetailsActionTypes.GET_VAT_DETAILS_LOADING,
            payload: {},
        });
        const response = yield call(getVatDetails, data);
        if (response.data.status) {
            yield put({
                type: VatDetailsActionTypes.GET_VAT_DETAILS_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: VatDetailsActionTypes.GET_VAT_DETAILS_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: VatDetailsActionTypes.GET_VAT_DETAILS_ERROR,
            payload: error,
        });
    }
}

export function* getVatDetailsAction(): any {
    yield takeEvery(
        VatDetailsActionTypes.GET_VAT_DETAILS_FIRST,
        callGetVatDetails
    );
}

function* getVatDetailsSaga(): any {
    yield all([
        fork(getVatDetailsAction),
    ]);
}
export default getVatDetailsSaga;
