// @flow
import { VerifyDetailActionTypes } from './constants';

export const getCompanyNameActions = (data) => (
    {
    type: VerifyDetailActionTypes.GET_COMPANY_NAME_FIRST,
    data,
});

export const registerUserActions = (data) => ({
    type: VerifyDetailActionTypes.REGISTER_USER_FIRST,
    data,
});

export const companyCheckVerifyActions = (data) => ({
    type: VerifyDetailActionTypes.COMPANY_CHECK_VERIFY_FIRST,
    data,
});

export const companyCreateActions = (data) => ({
    type: VerifyDetailActionTypes.COMPANY_CREATE_FIRST,
    data,
});

