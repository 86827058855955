import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { OtpSendEmailActionTypes } from './constants';
import { otpSendEmailEndPoint,subClientOtpSendEmailEndPoint } from './api';


function* otpSendEmailFunction(data) {
    try {
        yield put({
            type: OtpSendEmailActionTypes.OTP_SEND_EMAIL_LOADING,
            payload: {},
        });
        const response = yield call(otpSendEmailEndPoint, data);
        if (response.data.status) {
            yield put({
                type: OtpSendEmailActionTypes.OTP_SEND_EMAIL_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: OtpSendEmailActionTypes.OTP_SEND_EMAIL_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: OtpSendEmailActionTypes.OTP_SEND_EMAIL_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: OtpSendEmailActionTypes.OTP_SEND_EMAIL_ERROR,
            payload: error?.data,
        });
        yield put({
            type: OtpSendEmailActionTypes.OTP_SEND_EMAIL_RESET,
            payload: {},
        });

    }
}

function* subClientSendEmailVerifyFunction(data) {
    try {
        yield put({
            type: OtpSendEmailActionTypes.SUB_CLIENT_EMAIL_SEND_VERIFY_LOADING,
            payload: {},
        });
        const response = yield call(subClientOtpSendEmailEndPoint, data);
        if (response.data.status) {
            yield put({
                type: OtpSendEmailActionTypes.SUB_CLIENT_EMAIL_SEND_VERIFY_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: OtpSendEmailActionTypes.SUB_CLIENT_EMAIL_SEND_VERIFY_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: OtpSendEmailActionTypes.SUB_CLIENT_EMAIL_SEND_VERIFY_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: OtpSendEmailActionTypes.SUB_CLIENT_EMAIL_SEND_VERIFY_ERROR,
            payload: error?.data,
        });
        yield put({
            type: OtpSendEmailActionTypes.SUB_CLIENT_EMAIL_SEND_VERIFY_RESET,
            payload: {},
        });

    }
}



export function* acctionOtpSendEmail(): any {
    yield takeEvery(OtpSendEmailActionTypes.OTP_SEND_EMAIL_FIRST, otpSendEmailFunction);
}

export function* acctionSubClientEmailSendVerifyEmail(): any {
    yield takeEvery(OtpSendEmailActionTypes.SUB_CLIENT_EMAIL_SEND_VERIFY_FIRST, subClientSendEmailVerifyFunction);
}


function* otpSendEmailSaga(): any {
    yield all([
        fork(acctionOtpSendEmail),
        fork(acctionSubClientEmailSendVerifyEmail)

    ]);
}

export default otpSendEmailSaga;
