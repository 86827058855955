import { AuthComponentDataSend } from './constants';
import { StateEmptyActionTypes } from '../../stateEmpty/constants';

const OTP_TIME_START_INITIAL_STATE = {
    otpTimeStart: false,
    loading: false,
};

const SIGNUP_VERIFY_BACK_BTN_INITIAL_STATE = {
    signupVerifyBackBtnStop: false,
    loading: false,
};

const MOBILE_SCREEN_SIDE_BAR_CLOSE_INITIAL_STATE = {
    mbleCoseSideType: false,
    loading: false,
};


const otpTimeStartReducer = (
    state = OTP_TIME_START_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case AuthComponentDataSend.OTP_TIME_START_SUCCESS:
            return {
                otpTimeStart: true,
                loading: true,
            };
        case StateEmptyActionTypes?.STATE_EMPTY_SUCCESS:
            return OTP_TIME_START_INITIAL_STATE
        default:
            return state;
    }
};

const signupVerifyBackBtnTimeStopReducer = (
    state = SIGNUP_VERIFY_BACK_BTN_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case AuthComponentDataSend.SIGNUP_VERIFY_BACK_BTN_SUCCESS:
            return {
                signupVerifyBackBtnStop: true,
                loading: true,
            };
        case StateEmptyActionTypes?.STATE_EMPTY_SUCCESS:
            return SIGNUP_VERIFY_BACK_BTN_INITIAL_STATE

        default:
            return state;
    }
};

const mbleSccrnSideBrClseReducer = (
    state = MOBILE_SCREEN_SIDE_BAR_CLOSE_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case AuthComponentDataSend.MOBILE_SCREEN_SIDE_BAR_CLOSE_SUCCESS:
            return {
                mbleCoseSideType: true,
                loading: true,
            };
        case StateEmptyActionTypes?.STATE_EMPTY_SUCCESS:
            return MOBILE_SCREEN_SIDE_BAR_CLOSE_INITIAL_STATE

        default:
            return state;
    }
};

export {
    otpTimeStartReducer,
    signupVerifyBackBtnTimeStopReducer,
    mbleSccrnSideBrClseReducer
}
