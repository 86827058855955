import { ResetPasswordActionTypes } from './constants';

const RESET_PASSWORD_EMAIL_INITIAL_STATE = {
    resetPasswordEmail: [],
    loading: false,
};

const RESET_PASSWORD_VERIFY_INITIAL_STATE = {
    resetPasswordVerify: [],
    loading: false,
};


const resetPasswordEmailReducer = (
    state = RESET_PASSWORD_EMAIL_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case ResetPasswordActionTypes.RESET_PASSWORD_EMAIL_LOADING:
            return {
                resetPasswordEmail: state.resetPasswordEmail,
                loading: true,
            };
        case ResetPasswordActionTypes.RESET_PASSWORD_EMAIL_SUCCESS:
            return {
                resetPasswordEmail: action.payload,
                loading: false,
            };
        case ResetPasswordActionTypes.RESET_PASSWORD_EMAIL_ERROR:
            return {
                resetPasswordEmail: action.payload,
                loading: false,
            };
        case ResetPasswordActionTypes.RESET_PASSWORD_EMAIL_RESET:
            return RESET_PASSWORD_EMAIL_INITIAL_STATE;
        default:
            return state;
    }
};

const resetPasswordVerifyReducer = (
    state = RESET_PASSWORD_VERIFY_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case ResetPasswordActionTypes.RESET_PASSWORD_VERIFY_LOADING:
            return {
                resetPasswordVerify: state.resetPasswordVerify,
                loading: true,
            };
        case ResetPasswordActionTypes.RESET_PASSWORD_VERIFY_SUCCESS:
            return {
                resetPasswordVerify: action.payload,
                loading: false,
            };
        case ResetPasswordActionTypes.RESET_PASSWORD_VERIFY_ERROR:
            return {
                resetPasswordVerify: action.payload,
                loading: false,
            };
        case ResetPasswordActionTypes.RESET_PASSWORD_VERIFY_RESET:
            return RESET_PASSWORD_VERIFY_INITIAL_STATE;
        default:
            return state;
    }
};

export {
    resetPasswordEmailReducer,
    resetPasswordVerifyReducer
}
