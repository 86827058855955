import * as URL from "../../../helpers/api/apiEndPoint";
import { APICore } from "../../../helpers/api/apiCore";
const api = new APICore();

export function uploadProfileApi(payload): any {
  // console.log(payload, 'updateProfile')
  return api.update(URL.UPDATE_PROFILE, payload?.payload);
}

export function uploadPasswordApi(data): any {
  return api.update(URL.UPDATE_PASSWORD, data.payload)
}
export function getProfileApi(data): any {
  return api.get(URL.Get_PROFILE, data.payload);
}

export function accountDetailEndPoint(params) {
  const { data } = params;
  return api.update(URL.ACCOUNT_DETAIL_ACCOUNT, data);
}

export function mobileNumberVerifyEndPoint(params: any): any {
  const { data } = params;
  return api.create(URL.EMAIL_VERIFY_SEND, data);
}

export function mobileNumberVerifyUpdateEndPoint(params: any): any {
  const { data } = params;
  return api.create(URL.MOBILE_NUMBER_VERIFY, data);
}

export function mobileNumberVerifyOtpSendEndPoint(params: any): any {
  const { data } = params;
  return api.create(URL.MOBILE_NUMBER_VERIFY_OTP_SEND, data);
}
