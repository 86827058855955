import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { BusinessEmailActionTypes } from './constants';
import { businessEmailEndPoint } from './api';


function* businessEmailFunction(data) {
    try {
        // console.log(data, 'emailresp')
        yield put({
            type: BusinessEmailActionTypes.BUSINESS_EMAIL_LOADING,
            payload: {},
        });
        const response = yield call(businessEmailEndPoint, data);
        // console.log(response,'emailresp')
        if (response.data.status) {
            yield put({
                type: BusinessEmailActionTypes.BUSINESS_EMAIL_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: BusinessEmailActionTypes.BUSINESS_EMAIL_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: BusinessEmailActionTypes.BUSINESS_EMAIL_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        // console.log(error);
        yield put({
            type: BusinessEmailActionTypes.BUSINESS_EMAIL_ERROR,
            payload: error?.data,
        });
        yield put({
            type: BusinessEmailActionTypes.BUSINESS_EMAIL_RESET,
            payload: {},
        });
    }
}


export function* acctionBusinessEmail(): any {
    yield takeEvery(BusinessEmailActionTypes.BUSINESS_EMAIL_FIRST, businessEmailFunction);
}

function* businessEmailSaga(): any {
    yield all([
        fork(acctionBusinessEmail),

    ]);
}

export default businessEmailSaga;
