import { getAnnouncementsApi } from "./api";
import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { announcements } from "./constant";

function* getAnnouncementsFunction() {
  try {
    yield put({
      type: announcements.GET_ANNOUNCEMENT_LOADING,
      payload: {},
    });
    const response = yield call(getAnnouncementsApi);
    if (response.data.status) {
      yield put({
        type: announcements.GET_ANNOUNCEMENT_SUCCESS,
        payload: { ...response.data },
      });
    } else {
      yield put({
        type: announcements.GET_ANNOUNCEMENT_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: announcements.GET_ANNOUNCEMENT_ERROR,
      payload: { message: error?.message },
    });
  }
}

export function* getAnnouncementsSaga(): any {
  yield takeEvery(announcements.GET_ANNOUNCEMENT, getAnnouncementsFunction);
}

function* allAnnouncementSaga(): any {
  yield all([fork(getAnnouncementsSaga)]);
}

export default allAnnouncementSaga;
